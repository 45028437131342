import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";



//Nextconlab pages
import NextconLabAlumni from "./pages/NextconLabAlumni";
import NextconLabNews from "./pages/NextconLabNews";
import NextconLabHome from "pages/NextconLabHome";
import NextconLabMedia from "pages/NextconLabMedia";
import NextconLabMembers from "pages/NextconLabMembers";
import NextconLabPartnership from "pages/NextconLabPartnership";
import NextconLabSponsors from "pages/NextconLabSponsors";
import NextconLabSurveys from "pages/NextconLabSurveys";


function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/program" element={<Program />} /> */}
        {/* for Nextconlab */}
        <Route path="/" element={<NextconLabHome />} />
        <Route path="/members" element={<NextconLabMembers />} />
        <Route path="/alumni" element={<NextconLabAlumni />} />
        <Route path="/news" element={<NextconLabNews />} />
        <Route path="/surveys-tutorials" element={<NextconLabSurveys />} />
        <Route path="/sponsors" element={<NextconLabSponsors />} />
        <Route path="/collaborators" element={<NextconLabPartnership />} />
        <Route path="/media" element={<NextconLabMedia />} />

      </Routes>
    </Router>
  );
}

export default AppRouter;
