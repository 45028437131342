import React from 'react';
import { Flex, Box, Image } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function Goal() {
  return (
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                MISSION
            </Flex>
            <Box className={css(styles.textbox)}>
            <p align="justify">
                The mission of the Next Generation Communications and Computing Networks (NEXTCON) Research Laboratory and 
                the Smart Connected Vehicles Innovation Centre (SCVIC) is to pioneer cutting-edge research and innovation 
                in communications and computing technologies, integrating cybersecurity measures alongside 6G integration, 
                Artificial Intelligence (AI), and edge intelligence. We aim to advance the state-of-the-art in next-generation 
                networking solutions, enabling seamless connectivity for smart vehicles and beyond while prioritizing security. 
                Through interdisciplinary collaboration, industry partnerships, and a commitment to high-quality research 
                dissemination, we address complex challenges and drive transformative impact in the telecommunications, 
                information technology, and automotive industries.
            </p>
            </Box>
            <Flex align="center" justify="center" width="100%" mt={2}>
                <Image 
                    src="NEXTCON-image004.png" 
                    alt="nserc logo" 
                    width="70%" 
                    fit="contain"
                />
            </Flex>
        </Box> 
    )
}

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    }
})

export default Goal