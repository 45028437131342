import {Flex, Text} from "@chakra-ui/react";
import React from 'react';
import './Researchers.css';


function Researchers() {
  return (
    <>
      <Flex direction="column" align="center" >
              <Text style={{
                maxWidth: '100%',
                justifyContent: 'center',
                fontSize: 25,
                marginTop: '1%',
                fontWeight: 'bolder',
                textAlign: 'center',
                textDecorationLine: 'underline'
              }}>Former Postdoctoral and Research Associate Members</Text>
          </Flex>

      <Flex>
        <div className="researchers-container">
          <div className="researcher-info">
            <p><strong>Dr. Aynur Zhumadillayeva </strong> (2024)</p>
            <p> <u>Current Position:</u> <i>Associate PRofessor at L.N Gumilov Eurasian NAtional University |</i></p>
          </div>

          <div className="researcher-info">
            <p><strong>Othmane Friha </strong> (2024)</p>
            <p> <u>Current Position:</u> <i>Assistant Professor at University of Algiers |</i></p>
          </div>
          <div className="researcher-info">
            <p><strong>Omer Melih Gül </strong> (2023)</p>
            <p> <u>Current Position:</u> <i>Associate Professor in the Informatics Institute at Istanbul Technical University (ITU) |</i></p>
          </div>
          <div className="researcher-info">
            <p><strong>Tuerxun Wali </strong> (2022)</p>
            <p> <u>Current Position:</u> <i>Gnowi |</i></p>
          </div>
        </div>
      </Flex>

    </>
  );
}



export default Researchers;