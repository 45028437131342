import React from "react";
import {Text, Image, Link, Flex} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";


import './mediaTable.css'

function media() {
    return (
        <>
        <Flex direction="column" align="center" marginBottom="20px">
            <Text style={{
              maxWidth: '100%',
              justifyContent: 'center',
              fontSize: 30,
              margin: '1%',
              fontWeight: 'bolder',
              textShadow: '1px 1px gray',
              textAlign: 'center',
              textDecorationLine: 'underline'
            }}>NEXTCONLAB MEDIA</Text>
        </Flex>



        <Flex width="100%" alignItems="center">

        <div className="mediaTableMobile">
        {/* First Table */}
        <table className="mediaTableMobile" >
          <thead>
            <tr>
              <th>Source/Link/HeadLine</th>
            </tr>
          </thead>
          <tbody>
            {/* More rows Beginning */}
            <tr>
              <td>
               <Image src="med2.jpg" alt="med2.jpg" className={css(styles.images)} />
               <Link href="https://obj.ca/how-uottawa-empowers-local-startup-success-through-rd-collaborations/" className={css(styles.Newslink)} target="_blank">
              https://obj.ca/how-uottawa-empowers-local-startup-success-through-rd-collaborations/
                </Link>
                <Text className={css(styles.Newscontent)}>
                How uOttawa empowers local startup success through R&D collaborations,    3 December 2024
                </Text>
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* More rows as Ending */}
            {/* More rows Beginning */}
            <tr>
              <td>
               <Image src="media_2.jpg" alt="media_2.jpg" className={css(styles.images)} />
               <Link href="https://www.kanatanorthba.com/multiaccess-edge-computing-kanata-north/" className={css(styles.Newslink)} target="_blank">
                https://www.kanatanorthba.com/multiaccess-edge-computing-kanata-north/
                </Link>
                <Text className={css(styles.Newscontent)}>
                Kanata Networker, Multi-access edge computing accelerated in Kanata North,    30 June 2024
                </Text>
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            <tr>
              <td>
               <Image src="media_1.jpg" alt="media_1" className={css(styles.images)} />
               <Link href="https://obj.ca/uottawas-kanata-north-campus-marks-5-years-in-tech-park/" className={css(styles.Newslink)} target="_blank">
              https://obj.ca/uottawas-kanata-north-campus-marks-5-years-in-tech-park/
                </Link>
                <Text className={css(styles.Newscontent)}>
                Smart Connected Vehicles Innovation Centre was featured in Ottawa Business Journal, 22 February 2022
                </Text>
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* More rows as Ending */}



            {/* More rows Beginning */}
            <tr>
              <td>
               <Image src="Nextcon-Media1.gif" alt="Nextcon-Media1.gif" className={css(styles.images)} />
              <Link href="https://directory.libsyn.com/episode/index/show/iotnorth/id/22186334" className={css(styles.Newslink)} target="_blank">
               Things Happen Podcast Series. Episode 14: Innovating Smart Connected Vehicles and Cities
                </Link>
                <Text className={css(styles.Newscontent)}>
                 Things Happen Podcast on Lybsin,    22 February 2022
                </Text>
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* More rows as Ending */}
            {/* More rows Beginning */}
            <tr>
              <td>
               <Image src="med1.jpg" alt="med1.jpg" className={css(styles.images)} />
               <Link href="https://www.uottawa.ca/fr/nouvelles-gazette" className={css(styles.Newslink)} target="_blank">
                Drones, robots, voitures : le nouveau centre d’innovation de Kanata-Nord propulsera l’avenir des véhicules autonomes
                </Link>
                <Text className={css(styles.Newscontent)}>
                   uOttawa - Gazette, le 29 octobre 2021
                </Text>
              
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* More rows as Ending */}
            {/* rows Beginning */}
             <tr>
              <td>
               <Image src="med1.jpg" alt="med1" className={css(styles.images)} />
               <Link href="https://www.uottawa.ca/en/gazette-news" className={css(styles.Newslink)} target="_blank">
                Drones, bots and self-driving cars: How this new Kanata North innovation centre will help drive the future of autonomous vehicles
                </Link>
                <Text className={css(styles.Newscontent)}>
                    uOttawa - Gazette, 29 October 2021 
                </Text>
              
              
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              
              <Link href="https://obj.ca/article/Faculty-engineering-sponsored-creating-water-cooler-moments-drive-connected-car-innovation-kanata-north" className={css(styles.Newslink)} target="_blank">
                Ottawa Business Journal, 27 Sep. 2021
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                  Creating ‘water cooler moments’ to drive connected car innovation in Kanata North
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              
              <Link href="https://obj.ca/article/faculty-of-engineering-sponsored-uottawa-kanata-north-ramping-smart-connected-vehicles-innovation-lab" className={css(styles.Newslink)} target="_blank">
                Ottawa Business Journal, 18 Dec 2019
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                  uOttawa Kanata North ramping up Smart Connected Vehicles Innovation Lab
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med3.jpg" alt="med3" className={css(styles.images)} />
              <br/>
              <Image src="med7.jpg" alt="med7" className={css(styles.images)}/>
              <Link href="https://www.youtube.com/watch?v=YipJrqZea5E&t=3256s" className={css(styles.Newslink)} target="_blank">
              Click Here for the video
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   CAV Canada Panel: How CAVs will impact society
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
                 <Image src="med2.jpg" alt="med2" className={css(styles.images)} />
              
              <Link href="https://obj.ca/article/uottawa-engineering-search-safer-cities-university-ottawa-researchers-eye-new-uses-big-data-and-vehicular-crowd" className={css(styles.Newslink)} target="_blank">
                  Ottawa Business Journal, 24 June 2019
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   In search of safer cities, University of Ottawa researchers eye new uses for big data and vehicular crowd-sensing
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med4.gif" alt="med4" className={css(styles.images)} marginLeft="120px" />
              
              <Link href="https://twitter.com/TheOfficialACM/status/1083418240699772929?s=20" className={css(styles.Newslink)} target="_blank">
                 ACM DSP Announcement
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   ACM Distinguished Speaker Dr. Burak Kantarci
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
              
             
              <Link href="https://www.youtube.com/watch?v=52b8uznYf4o" className={css(styles.Newslink)} target="_blank">
              TAF Network, -
                </Link>
                
                <Text className={css(styles.Newscontent)}>
                 Interview with TAFNetwork (In Turkish)
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med5.jpg" alt="med5" className={css(styles.images)} />
              
              <Link href="https://nextconlab.ca/mpcourier-bestpaper.pdf.pdf" className={css(styles.Newslink)} target="_blank">
                 Daily Courier Observer, 03/11/2016
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   CU Researchers Write "Best Paper"
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med6.jpg" alt="med6" className={css(styles.images)} marginLeft="90px"/>
              
              <Link href="https://www.clarkson.edu/news/2015/news-release_2015-08-31-1.html" className={css(styles.Newslink)} target="_blank">
                 Clarkson News Release, 08/31/2015
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   CU Receives Support from Qualcommy
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
            {/* rows Beginning */}
            <tr>
              <td>
               <Image src="med5.jpg" alt="med5" className={css(styles.images)} />
              <Link href="https://nextconlab.ca/DCO08042015.pdf" className={css(styles.Newslink)} target="_blank">
                 Daily Courier Observer, 08/04/2015
                </Link>
              
                <Text className={css(styles.Newscontent)}>
                   CU's Kantarci Elected Committee Secretary
                </Text>
              </td>
            </tr>
            <br/> <hr className={css(styles.hrStyle)}/>
            {/* rows as Ending */}
          </tbody>
        </table>

      </div>

      </Flex>
    </>
    );
  }
  
  const styles = StyleSheet.create({
    images: {
        fit: "fit",
        position: 'relative',
        maxWidth: '100%', // Ensures images don't overflow their container
        height: 'auto',
        justifyContent: 'center',
        display: 'flex',
        margin: '0 auto',
    },
    Newslink: {
        textDecoration: 'underline',
        width: '100%',
        color:"darkblue",
        fontWeight:"bold",
        alignContent:"center",
        textAlign:"center",
        whiteSpace: 'normal',
        position: 'relative',
        justifyContent: 'center',
    },
    Newscontent: {
        width: '100%',
        fontWeight:"bold",
        alignContent:"center",
        textAlign:"center",
        position: 'relative',
        justifyContent: 'center',
    },

    hrStyle: {
        height: '2px', // Increases the thickness of the line
        backgroundColor: 'black', // Sets the color of the line, change as needed
        border: 'none', // Removes any default border styling
        margin: '0 auto', // Centers the line horizontally if needed
        width: '100%' // Adjust this value to set the desired width
      },
})


  export default media;