import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function Goal() {
  return (
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                VISION
            </Flex>
            <Box className={css(styles.textbox)}>
            <p align="justify">
                Our vision is to create a future where smart and critical infrastructures and connected vehicles 
                form a seamlessly integrated ecosystem, fortified by 6G capabilities, AI-driven insights, edge intelligence,
                and robust cybersecurity measures. At the NEXTCON Research Laboratory & SCVIC, we envision revolutionizing 
                the way critical infrastructures (e.g., vehicular networks) communicate and interact with their environment, 
                laying the foundation for autonomous driving, intelligent transportation systems, and novel mobility services. 
                Through innovative research and development efforts, strategic industrial collaborations, and a determined 
                focus on cybersecurity, we aim to establish ourselves as global leaders in shaping the future of connected 
                mobility. Leveraging 6G advancements, AI, edge intelligence, and cybersecurity, we unlock new possibilities 
                for telecommunications, information technologies and smart transportation, ensuring security is ingrained in 
                every aspect of our advancements.
             </p>
            </Box>
        </Box> 
    )
}

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    }
})

export default Goal