import React from "react";
import {Flex, Text} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import './gradTable.css'


function AlumniTable() {

  const phdStudents = [
    { name: "Ahmed Omara", text: "University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in October 2024.", position: "Department of National Defense'te Machine Learning Engineer" },
    { name: "Bin Xiao", text: "University of Ottawa, Ph.D. in Computer Science awarded in September 2024.", position: "Postdoctoral researcher at University Health Network (UHN)" },
    { name: "Zhiyan Chen", text: "University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2023.", position: "Fortinet; machine learning security engineer" },
    { name: "Liu Jinxin", text: "University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2023.", position: "Huawei" },
    { name: "Andressa Vergütz", text: "University of Ottawa, Ph.D. in Computer Science awarded in June 2022.", position: "Haijunka 360 co-founder" },
    { name: "Safa Otoom", text: "University of Ottawa, Ph.D. in Electrical and Computer Engineering awarded in June 2019.", position: "Assistant Professor, Zayed University-Abu Dhabi" },
    { name: "Maryam Pouryazdanpanah", text: "Clarkson University, Ph.D in ECE awarded in August 2018.", position: "IoT-Data Scientist, Watts Water Technologies (Boston, MA)" },
    { name: "Fazel Anjomshoa", text: "Clarkson University, Ph.D in ECE awarded in May 2018", position: "Data Scientist at Roku Inc., (Boston ,MA)" },
    { name: "Claudio Fiandrino", text: "University of Luxembourg (visiting phd student), PhD in Computer Science awarded in November 2017", position: "Postdoctoral researcher, Imdea Networks (Madrid, Spain)" },
    { name: "Riccardo Venanzi", text: "(Visiting PhD student from University of Ferrara, Italy)", position: "Ph.D candidate in Computer Science, University of Ferrara, Italy" },
    { name: "Dimitri Belli", text: ", (Visiting PhD student from University of Pisa, Italy)", position: "Ph.D candidate in Computer Science, University of Pisa, Italy" }
  ];

  const mastersStudents = [
    { name: "Claire Zhang", text: "University of Ottawa, Master in System Science awarded in 2024.", position: "Research Trainee, European Space Agency" },
    { name: "Murat Arda Onsu", text: "University of Ottawa, Master in Computer Science awarded in 2023.", position: "Ph.D student, University of Ottawa" },
    { name: "Mohsen Shahbazi", text: "University of Ottawa, Master in Electrical and Computer Engineering awarded in 2023.", position: "Cisco" },
    { name: "Nahid Parvaresh", text: "University of Ottawa, Master in Computer Science awarded in 2023", position: "Advanced Symbolics" },
    { name: "Yu Shen", text: "University of Ottawa, Master in Electrical and Computer Engineering awarded in 2022.", position: "Nokia" },
    { name: "Johan Fernandes", text: "University of Ottawa, Master in Computer Science awarded in 2022.", position: "Statistics Canada" },
    { name: "Yakup Akkaya", text: "University of Ottawa, Master in Electrical and Computer Engineering awarded in 2022.", position: "J.D. Power" },
    { name: "Cem Mumtaz Eris", text: "Master in Computer Science awarded in 2022.", position: "Entrapeer" },
    { name: "Sedevizo Kielienyu", text: "Master of Computer Science, uOttawa, August 2020", position: "Data Scientist, Coastal Resource Mapping (CRM), Vancouver, BC" },
    { name: "Ji Chu Jiang", text: "Master of Applied Science, Electrical and Computer Engineering, uOttawa, December 2020", position: "Software Engineer at Lytica Inc., Ottawa, ON" },
    { name: "Kyle Quintal", text: "Master of Computer Science, University of Ottawa, August 2020", position: "Data Scientist, AedoAI (Calgary, AB)" },
    { name: "Yueqian Zhang", text: "Master of Computer Science, University of Ottawa, Dec 2019", position: "Recent graduate" },
    { name: "Ahmed Omara", text: "Master of Applied Science in Electrical and Computer Engineering, University of Ottawa, Dec 2019", position: "Ph.D student, University of Ottawa" },
    { name: "Ertugrul Kara", text: "Master of Computer Science, University of Ottawa, July 2019", position: "Software Engineer, Amazon (Vancouver, BC)" },
    { name: "Venkat Surya Dasari", text: "Master of Applied Science in Electrical and Computer Engineering, University of Ottawa, July 2019", position: "Researcher" },
    { name: "Ankkita Sood", text: "Master of Computer Science, University of Ottawa, June 2019", position: "Data analyst, Kinaxis (Ottawa, ON)" },
    { name: "Cem Kaptan", text: "Master of Computer Science, University of Ottawa, August 2018", position: "Software Developer, Wirkn (Montréal, QC) (also continuing Ph.D at NEXTCON" },
    { name: "Zachary I. Rauen", text: "M.Sc in Electrical Engineering, Clarkson University, May 2018", position: "Firmware/Security Team member, IBM (Schenectady, NY)" },
    { name: "Wendong Yuan", text: "Master of Computer Science, University of Ottawa, June 2018", position: "oftware Engineer at Fortinet Inc. (Ottawa, ON)" },
    { name: "George Patrick Xavier", text: "Master of Computer Science, University of Ottawa, June 2018", position: "Digital Integration Solutions Developer at Deloitte Canada (Montréal, QC)" },
    { name: "Damanpreet Singh", text: "Master of Computer Science, University of Ottawa, June 2018", position: "Current Position and Affiliation: Software Development Engineer, IBM (Ottawa, ON)" },
  ];

  const tableRefs = { phd: useRef(null), masters: useRef(null) };
  const [tableHeight, setTableHeight] = useState("auto");

  useEffect(() => {
    if (tableRefs.phd.current && tableRefs.masters.current) {
      const phdHeight = tableRefs.phd.current.clientHeight;
      const mastersHeight = tableRefs.masters.current.clientHeight;
      const maxHeight = Math.max(phdHeight, mastersHeight);
      setTableHeight(`${maxHeight}px`);
    }
  }, [tableRefs.phd, tableRefs.masters]);

  const StudentTableMobile = ({ title, students }) => {
    return (
      <table className="gradTable">
        <thead>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={index}>
              <td>
                <b>{student.name}</b>, {student.text}<br />
                <u>Current Position and Affiliation:</u> <i>{student.position}</i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };


  const StudentTable = ({ title, students, ref }) => {
    const [page, setPage] = useState(0);
    const itemsPerPage = 8;
    const totalPages = Math.ceil(students.length / itemsPerPage);

    const handleNext = () => {
      if (page < totalPages - 1) setPage(page + 1);
    };

    const handlePrev = () => {
      if (page > 0) setPage(page - 1);
    };

    return (
      <div ref={ref} style={{ textAlign: "center", width: "50%", padding: "10px", height: tableHeight }}>
        <table className="gradTable" style={{ margin: "10px auto", width: "100%", borderSpacing: "5px", height: "100%" }}>
          <thead>
            <tr>
              <th>{title}</th>
            </tr>
          </thead>
          <tbody>
            {students.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((student, index) => (
              <tr key={index}>
                <td style={{ padding: "10px", verticalAlign: "top" }}>
                  <b>{student.name}</b>, {student.text}
                  <br />
                  <u>Current Position and Affiliation:</u> <i>{student.position}</i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button onClick={handlePrev} disabled={page === 0}>◀ Previous</button>
          <span style={{ margin: "0 10px" }}>{page + 1} / {totalPages}</span>
          <button onClick={handleNext} disabled={page === totalPages - 1}>Next ▶</button>
        </div>
      </div>
    );
  };
  

  const isMobile = useSelector(state => state.windowSize.isMobile);
  return (
      <>
      {isMobile ? 
      (<>
          <Flex direction="column" align="center">
            <Text style={{
              maxWidth: '100%',
              justifyContent: 'center',
              fontSize: 30,
              margin: '1%',
              fontWeight: 'bolder',
              textShadow: '1px 1px gray',
              textAlign: 'center',
              textDecorationLine: 'underline'
            }}>PREVIOUS MEMBERS</Text>
        </Flex>



        <Flex direction="column" align="center">
            <Text style={{
              maxWidth: '100%',
              justifyContent: 'center',
              fontSize: 25,
              marginTop: '1%',
              fontWeight: 'bolder',
              textAlign: 'center',
              textDecorationLine: 'underline'
            }}>Graduate Students (Ph.D and Master's in chronological order)</Text>
        </Flex>

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <StudentTableMobile title="Ph.D. Students" students={phdStudents} />
            <StudentTableMobile title="Master's Students" students={mastersStudents} />
          </div>

      </>) : 
      (<>
          <Flex direction="column" align="center" >
            <Text style={{
              maxWidth: '100%',
              justifyContent: 'center',
              fontSize: 30,
              margin: '1%',
              fontWeight: 'bolder',
              textShadow: '1px 1px gray',
              textAlign: 'center',
              textDecorationLine: 'underline'
            }}>PREVIOUS MEMBERS</Text>
        </Flex>

        <Flex direction="column" align="center" >
            <Text style={{
              maxWidth: '100%',
              justifyContent: 'center',
              fontSize: 25,
              marginTop: '1%',
              fontWeight: 'bolder',
              textAlign: 'center',
              textDecorationLine: 'underline'
            }}>Graduate Students (Ph.D and Master's in chronological order)</Text>
        </Flex>
          
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", alignItems: "stretch", width: "80%", margin: "0 auto", padding: "1px", marginBottom:"40px" }}>
            <StudentTable title="Ph.D. Students" students={phdStudents} ref={tableRefs.phd} />
            <StudentTable title="Master's Students" students={mastersStudents} ref={tableRefs.masters} />
          </div>
      </>)}


    </>
  );
}
//marginLeft:"80px", marginRight:"80px"
  


export default AlumniTable;